<template>
  <UModal v-model="isOpen" prevent-close>
    <UCard>
      <template #header> Create new deal </template>

      <UFormGroup label="Deal Type" :required="true" name="deal_type">
        <Multiselect
          v-model="deal.deal_type"
          :options="dealTypes.data"
          label="title"
          track-by="title"
          placeholder="Select deal type"
          :multiple="false"
          group-values="subcategories"
          group-label="title"
        />
      </UFormGroup>

      <UFormGroup label="Target Company">
        <AsyncSearchDropdown
          v-model="deal.dealCompanies"
          label="name"
          :multiple="false"
          placeholder="Select company"
          source="companies"
        />
      </UFormGroup>

      <UFormGroup label="Deal Stage" name="deal_stage">
        <Multiselect
          v-model="deal.deal_stage"
          :options="dealStages.data"
          label="title"
          track-by="title"
          placeholder="Select deal stage"
          :multiple="false"
        />
      </UFormGroup>

      <UFormGroup label="Deal size ($mm)">
        <UInput v-model="deal.close_amount">
          <template #leading>$</template>
        </UInput>
      </UFormGroup>

      <UFormGroup label="Close Date">
        <UInput v-model="deal.close_date" type="date" />
      </UFormGroup>

      <UFormGroup label="News article link">
        <UInput v-model="deal.links.article" placeholder="Link (optional)" />
      </UFormGroup>

      <UFormGroup label="Description">
        <UTextarea v-model="deal.description" size="xl" />
      </UFormGroup>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton @click="createDeal" color="green" label="Create" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const isOpen = ref(false);

const dealTypes = await useApiDropdownFetch("deal-types");
const dealStages = await useApiDropdownFetch("deal-stages");

const deal = ref({
  dealCompanies: [],
  deal_stage: null,
  deal_type: null,
  close_amount: null,
  close_date: null,
  links: [
    {
      article: null,
    },
  ],
  description: null,
});

const emit = defineEmits(["created", "error", "close"]);
async function createDeal() {
  try {
    const response = await useApiPost("deals", {
      body: deal.value,
    });

    if (response.success) {
      deal.value = {
        target_company: [],
        deal_stage: null,
        deal_type: null,
        close_amount: null,
        close_date: null,
        links: [
          {
            article: null,
          },
        ],
        description: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
