<template>
  <UModal :ui="{ width: 'w-full sm:max-w-6xl' }">
    <UCard>
      <template #header> Create new user </template>

      <fieldset class="space-y-4">
        <UFormGroup label="User Type">
          <Multiselect
            v-model="user.types"
            :options="userTypes.data"
            label="title"
            track-by="id"
            :multiple="true"
            placeholder="Select user type"
          />
        </UFormGroup>
        <div class="grid grid-cols-2 gap-4">
          <UFormGroup label="Contact name">
            <UInput v-model="user.name" />
          </UFormGroup>

          <UFormGroup label="Default (Login) Email">
            <UInput v-model="user.email" />
          </UFormGroup>
        </div>

        <div class="grid grid-cols-4 gap-4">
          <UFormGroup label="City" name="address.city">
            <UInput v-model="user.address.city" />
          </UFormGroup>

          <UFormGroup label="State" name="address.state">
            <UInput v-model="user.address.state" />
          </UFormGroup>

          <UFormGroup label="Country" name="address.country">
            <UInput v-model="user.address.country" />
          </UFormGroup>

          <UFormGroup label="Zip" name="address.zip">
            <UInput v-model="user.address.zip" />
          </UFormGroup>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <UFormGroup label="FastPeopleSearch Link">
            <UInput v-model="user.fps_link" placeholder="FPS link" />
          </UFormGroup>

          <UFormGroup label="LinkedIn Link">
            <UInput v-model="user.linked_in_link" placeholder="LinkedIn link" />
          </UFormGroup>
        </div>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton @click="createUser" color="green" label="Create" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const userTypes = await useApiDropdownFetch("user-types");

const user = ref({
  types: [],
  name: null,
  email: null,
  address: {
    city: null,
    state: null,
    country: null,
    zip: null,
  },
  contacts: {
    emails: [
      {
        email: "",
        type: "work",
        default: false,
      },
    ],
    phones: [
      {
        phone: "",
        type: "work",
        default: true,
      },
    ],
  },
  fps_link: null,
  linked_in_link: null,
});

const emit = defineEmits(["created", "error", "close"]);
async function createUser() {
  try {
    const response = await useApiPost("users", {
      body: user.value,
    });

    if (response.success) {
      user.value = {
        types: [],
        name: null,
        email: null,
        address: {
          city: null,
          state: null,
          country: null,
          zip: null,
        },
        contacts: {
          emails: [
            {
              email: null,
              type: "work",
            },
          ],
          phones: [
            {
              phone: null,
              type: "work",
            },
          ],
        },
        fps_link: null,
        linked_in_link: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
