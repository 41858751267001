<template>
  <UModal v-model="isOpen" prevent-close>
    <UCard>
      <template #header> Create new company </template>

      <!-- when ticker is specified submit to a different endpoint -->
      <!-- `/companies-import-tickers` -->
      <UFormGroup label="Import ticker(s) (comma separated)">
        <UInput
          v-model="company.tickers"
          placeholder="This currently does not work!"
        />
      </UFormGroup>

      <div class="flex items-center">
        <div class="flex-grow border-t border-gray-300"></div>
        <div class="mx-4 text-gray-500">OR</div>
        <div class="flex-grow border-t border-gray-300"></div>
      </div>

      <UFormGroup label="Company Name">
        <UInput v-model="company.name" :required="true" />
      </UFormGroup>

      <UFormGroup label="Company Type" :required="true">
        <multiselect
          v-model="company.company_type"
          :options="companyType.data"
          label="title"
          track-by="title"
          placeholder="Select company type"
        />
      </UFormGroup>

      <UFormGroup label="Finance Stage" :required="true">
        <multiselect
          v-model="company.finance_stage"
          :options="financeStage.data"
          label="title"
          track-by="title"
          placeholder="Select finance stage"
        />
      </UFormGroup>

      <UFormGroup
        v-if="company.finance_stage?.slug === 'public'"
        label="Ticker"
        name="ticker"
      >
        <UInput v-model="company.ticker" />
      </UFormGroup>

      <UFormGroup label="Industries">
        <multiselect
          v-model="company.industries"
          :options="industries.data"
          label="title"
          track-by="id"
          placeholder="Choose industries"
          :multiple="true"
          group-values="subindustries"
          group-label="title"
        />
      </UFormGroup>

      <UFormGroup label="City" name="city">
        <UInput v-model="company.address.city" />
      </UFormGroup>

      <UFormGroup label="State" name="state">
        <UInput v-model="company.address.state" />
      </UFormGroup>

      <UFormGroup label="Country" name="country">
        <UInput v-model="company.address.country" />
      </UFormGroup>

      <UFormGroup label="Website" name="website">
        <UInput v-model="company.links.website" />
      </UFormGroup>

      <UFormGroup label="Dataroom link" name="dataroom">
        <UInput v-model="company.links.dataroom" />
      </UFormGroup>

      <UFormGroup label="Description">
        <UTextarea v-model="company.description" />
      </UFormGroup>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton color="green" @click="createCompany">Create</UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const isOpen = ref(false);

const company = ref({
  name: null,
  company_type: null,
  finance_stage: null,
  // company_type_id: null,
  // finance_stage_id: null,
  industries: [],
  tickers: [],
  ticker: null,
  address: [
    {
      city: null,
      state: null,
      country: null,
    },
  ],
  links: {
    website: null,
    dataroom: null,
  },
  description: null,
});

const companyType = await useApiDropdownFetch("company-types");
const financeStage = await useApiDropdownFetch("finance-stages");
const industries = await useApiDropdownFetch("industries");

const emit = defineEmits(["created", "error", "close"]);
async function createCompany() {
  const endpoint = company.value.tickers.length
    ? "companies-import-tickers"
    : "companies";
  try {
    const response = await useApiPost(endpoint, {
      body: company.value,
    });

    if (response.success) {
      company.value = {
        name: null,
        company_type: null,
        finance_stage: null,
        industries: [],
        tickers: [],
        ticker: null,
        address: [
          {
            city: null,
            state: null,
            country: null,
          },
        ],
        links: {
          website: null,
          dataroom: null,
        },
        description: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
