<template>
  <UModal>
    <UCard>
      <template #header>Create new institution</template>

      <fieldset class="space-y-4">
        <UFormGroup label="Name" name="name">
          <UInput v-model="institution.name" />
        </UFormGroup>

        <UFormGroup label="Type" name="types">
          <multiselect
            v-model="institution.types"
            :options="institutionTypes.data"
            label="title"
            track-by="title"
            placeholder="Select type"
            multiple="true"
          />
        </UFormGroup>

        <UFormGroup label="City" name="city">
          <UInput v-model="institution.address.city" />
        </UFormGroup>

        <UFormGroup label="State" name="state">
          <UInput v-model="institution.address.state" />
        </UFormGroup>

        <UFormGroup label="Country" name="country">
          <UInput v-model="institution.address.country" />
        </UFormGroup>

        <UFormGroup label="Background" name="background">
          <UTextarea v-model="institution.background" />
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton @click="createInstitution" color="green" label="Create" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const institutionTypes = await useApiDropdownFetch("institutional-types");

const institution = ref({
  name: null,
  types: [],
  address: [
    {
      city: null,
      state: null,
      country: null,
    },
  ],
  background: null,
});

const toast = useToast();
const emit = defineEmits(["created", "error", "close"]);
async function createInstitution() {
  try {
    const response = await useApiPost("institutions", {
      body: institution.value,
    });

    if (response.success) {
      institution.value = {
        name: null,
        types: [],
        address: [
          {
            city: null,
            state: null,
            country: null,
          },
        ],
        background: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
