<template>
  <UModal>
    <UCard>
      <template #header>Create new project</template>

      <fieldset class="space-y-4">
        <UFormGroup label="Title" name="title">
          <UInput v-model="project.title" />
        </UFormGroup>

        <UFormGroup
          label="Select target company"
          name="target_company"
          :required="true"
        >
          <AsyncSearchDropdown
            v-model="project.target_company"
            label="name"
            :multiple="false"
            placeholder="Select target company"
            source="companies"
          />
        </UFormGroup>

        <UFormGroup label="Select associated deal" name="deal_id">
          <multiselect
            v-model="project.deal"
            :options="deals.data"
            label="title"
            track-by="title"
            :multiple="false"
            placeholder="Select a deal"
          />
        </UFormGroup>

        <UFormGroup label="Engagement date" name="engaged_at">
          <UInput v-model="project.engaged_at" type="date" />
        </UFormGroup>

        <UFormGroup label="Comment" name="comment">
          <UTextarea v-model="project.comment" size="xl" />
        </UFormGroup>
      </fieldset>

      <template #footer>
        <div class="flex justify-between">
          <UButton color="gray" @click="$emit('close')" label="Cancel" />
          <UButton color="green" @click="createProject" label="Create" />
        </div>
      </template>
    </UCard>
  </UModal>
</template>

<script setup>
import Multiselect from "vue-multiselect";

const project = ref({
  title: null,
  target_company: {},
  deal: {},
  engaged_at: null,
  comment: null,
});

const deals = await useApiDropdownFetch("deals");

// function customLabel({ title, stage }) {
//   return `${title} – ${stage}`;
// }

const emit = defineEmits(["created", "error", "close"]);
async function createProject() {
  try {
    const response = await useApiPost("projects", {
      body: project.value,
    });

    if (response.success) {
      project.value = {
        title: null,
        target_company: {},
        deal: {},
        engaged_at: null,
        comment: null,
      };
      emit("created");
    }
  } catch (error) {
    emit("error", error);
  }
}
</script>
